body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #121212; /* Fond sombre */
  color: #ffffff;
  font-family: Arial, sans-serif;
}

.container {
  text-align: center;
}

.loading {
  font-size: 3rem; /* Agrandir la taille de la police */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading div {
  width: 20px; /* Agrandir les cercles */
  height: 20px; /* Agrandir les cercles */
  margin: 10px; /* Augmenter l'espace entre les cercles */
  background: #ffffff;
  border-radius: 50%;
  animation: loading 0.6s infinite alternate;
}

.loading div:nth-child(1) {
  animation-delay: 0s;
}

.loading div:nth-child(2) {
  animation-delay: 0.2s;
}

.loading div:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loading {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0.5;
  }
}

.sub-text {
  font-size: 1.5rem;
  opacity: 0;
  animation: fadeIn 2s ease-in-out 1s forwards;
  margin-top: 40px; /* Ajouter plus d'espace entre l'animation et le texte */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
